//template中的代码
<template>
  <div>
    <div class="boxshow">
      <div id="qrcode" class="qrcode" ref="qrcodeContainer">
        <img v-if="QRCurl"  class="image" :src="logo"  alt="">
        <div style="text-align:right;position: absolute;bottom:84px;right:85px">{{ QRCName }}</div>
      </div>
    </div>
    <div style="padding:0 80px 60px 80px">
      <el-button style="width: 100%" type="primary" icon="el-icon-download" @click="clickGeneratePicture()">下载</el-button>
    </div>
  </div>

</template>
<script>
import QRCode from 'qrcodejs2' // 引入qrcode
import html2canvas from "html2canvas"
export default {
  name: 'test',
  props: {
    QRCodetext: {
      type: String,//类型限定
      default: '' //默认
    },
    QRCurl: {
      type: String,//类型限定
      default: '' //默认
    },
    QRCName: {
      type: String,//类型限定
      default: '' //默认
    }
  },
  data(){
    return{
      logo:'',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.qrcode()
    })
  },
  created() {
    this.imageUrlToBase64(this.QRCurl)
  },
  methods: {
    imageUrlToBase64(img) {
      // 一定要设置为let，不然图片不显示
      let image = new Image();
      // 解决跨域问题
      image.setAttribute('crossOrigin', 'anonymous');
      let imageUrl = img;
      image.src = imageUrl;
      // image.onload为异步加载
      image.onload = () => {
        this.getImage(image);
      };
    },

    getImage(image) {
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, image.width, image.height);
      let quality = 0.8;
      // 这里的dataurl就是base64类型
      this.logo = canvas.toDataURL('image/jpeg', quality);
    },

    clickGeneratePicture() { //生成图片
      html2canvas(this.$refs.qrcodeContainer).then(canvas => {
        // 转成图片，生成图片地址
        this.imgUrl = canvas.toDataURL("image/png"); //可将 canvas 转为 base64 格式
      });
      let that=this
      setTimeout(function () {
        that.downloadIamge(that.imgUrl,'['+that.QRCName+']二维码')
      },500)

    },
    downloadIamge(imgsrc, name) {//下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        canvas.useCORS = true;
        canvas.onclone = this.QRCurl;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    qrcode() {
      console.log(this.QRCodetext)
      console.log(this.QRCurl)
      let qrcode = new QRCode(this.$refs.qrcodeContainer, {
        width: 300,// 二维码的宽
        height: 300,// 二维码的高
        margin:20,
        text: this.QRCodetext ? this.QRCodetext : '', // 二维码的内容
        colorDark: '#000',// 二维码的颜色
        colorLight: '#fff',
        logo:this.QRCurl,
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    // download() {
    //   var canvasData = this.$refs.qrcodeContainer.getElementsByTagName('canvas')
    //   var a = document.createElement("a");
    //   var event = new MouseEvent("click"); // 创建一个单击事件
    //   a.href = canvasData[0].toDataURL("image/png");;
    //   a.download = "二维码";
    //   a.dispatchEvent(event); // 触发a的单击事件
    // },
    download() {

    },

  }
}
</script>

<style lang='less'>
.boxshow{
  position: relative;
  padding: 80px;
}
#qrcode{
  padding-bottom: 20px!important;
}
.image{
  z-index: 9999;
  padding: 5px;
  height: 55px;
  width: 55px;
  background: white;
  position: absolute;
  top:200px;
  left:200px;
}
</style>
